<template>
  <div class="container">
    <div class="row" v-if="$store.state.nftContract">
      <div class="col-lg-3 col-sm-12 text-justify">
        <h3 id="nft-name">
          <strong>
            <router-link v-bind:to="'/rat/find/' + rat['rat-name']">
              {{ rat['rat-name'] }}
            </router-link> [#{{ rat['rat-number'] }}]
          </strong>
          <br/>
        </h3>
        <p v-if="owner">
          <strong>Owner: </strong> <MetamaskAddress  v-bind:address="owner" short="yes"></MetamaskAddress>
        </p>
        <p v-if="owner && $store.state.address && owner.toLowerCase() === $store.state.address.toLowerCase()">
          <router-link v-if="owner && $store.state.address && $store.state.address === owner.toLowerCase()" v-bind:to="'/rat/' + rat['rat-number']" class="btn btn-primary">Fitting room</router-link>
        </p>
        <Image v-if="nft.equipment" v-bind:equipment="nft.equipment"></Image>
        <p class="badges">
          <span class="badge bg-secondary" v-if="rat.generation == 2">Series: Xmas Special edition 🎅</span>
          <span class="badge bg-secondary" v-if="rat.duplicates == 1">Unique ⭐️</span>
          <span class="badge bg-secondary iq" v-if="rat.power">IQ: {{ rat.power }} 🧠</span>
        </p>
        <div v-if="rat.network === 'SLP'">
          <a target="_blank" v-bind:href="'https://simpleledger.info/token/' + metadata.hash">
            <img src="/images/slp.png" />
          </a>
        </div>
        <div v-if="rat.network === 'CT'">
          <img src="/images/cashtokens-logo.svg" class="img-fluid" />
        </div>
        <div v-if="rat.network === 'SmartBCH'">
          <img src="/images/smartbch.png" width="256" class="img-fluid" />
        </div>
      </div>
      <div class="col-lg-9 col-sm-12">
        <div class="col-12" v-if="rat.network === 'SmartBCH' && owner && (owner.toLowerCase() === '0x657061bf5D268F70eA3eB1BCBeb078234e5Df19d'.toLowerCase() || owner.toLowerCase() === '0x3b968177551a2aD9fc3eA06F2F41d88b22a081F7'.toLowerCase())">
          <a v-bind:href="'https://oasis.cash/token/0xD27CFd5b254E8D958c0c8e7f99E17A1e33652C1A/' + rat['number']" target="_blank">
            Available on OASIS &raquo;
          </a>
        </div>
        <template v-if="owner">
          <Burn
              v-if="rat.network === 'SmartBCH' && owner && $store.state.address && owner.toLowerCase() == $store.state.address.toLowerCase()"
              v-bind:id="rat['rat-number']">
          </Burn>

          <Transfer
              v-if="rat.network === 'SmartBCH' && owner && $store.state.address && owner.toLowerCase() == $store.state.address.toLowerCase()"
              v-bind:ca="nftCA"
              v-bind:id="rat['rat-number']">
          </Transfer>
        </template>

        <div v-if="rat.attributes">
          <strong>
            Base traits
          </strong>: <br/>
          <div class="container">
            <div class="row">
              <template v-for="attribute in rat.attributes" :key="attribute.trait_type">
                <div class="col-md-4 col-sm-6 col-12" v-if="typeof attribute.power === 'undefined' || attribute.power === 0">
                  <div class="card mb-3 text-center">
                    <div class="card-body">
                      <div class="d-flex justify-content-center">
                        <small>{{ attribute.trait_type }}</small>
                      </div>
                      <div class="d-flex justify-content-center">
                        <strong>{{ attribute.value }}</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>

        <div v-if="nft.hasEquipment">
          <strong>
            Equipped traits
          </strong>: <br/>
          <div class="container">
            <div class="row">
              <template v-for="attribute in rat.attributes" :key="attribute.trait_type">
                <div class="col-md-4 col-sm-6 col-12" v-if="attribute.power > 0">
                  <div class="card mb-3 text-center">
                    <div class="card-body">
                      <div class="d-flex justify-content-center">
                        <small>{{ attribute.trait_type }}</small>
                      </div>
                      <div class="d-flex justify-content-center">
                        <strong>{{ attribute.value }} - 🧠 {{ attribute.power }}</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>

        <p v-if="rat['rat-data'] && rat['rat-data'].length > 0">
          <strong>Data</strong><br/>
          <code>{{ rat['rat-data'] }}</code>
        </p>
      </div>
    </div>
  </div>
</template>

<style>
p.badges .badge {
  margin: 5px;
}
</style>

<script>
  import Transfer from "@/components/Rat/Transfer";
  import Burn from "@/components/Rat/Burn";
  import Image from "@/components/Rat/Image";
  import MetamaskAddress from "@/components/MetamaskAddress";
  import {nftCA} from "../../../config";

  export default {
    components: {
      MetamaskAddress,
      Transfer,
      Image,
      Burn
    },
    data() {
      return {
        nftCA: nftCA,
        similarRats: [],
        owner: null,
        power: 0,
        nft: {
          metadata: [],
          equipment: null,
          hasEquipment: false,
        },
        rat: {
          firstname: null,
          name: null,
          number: null,
          hash: null,
          image: null,
          'rat-number': null,
          'rat-data': null,
          traits: {},
          duplicates: null,
          lookup: null,
          generation: 1,
          smart: true,
          network: null
        },
      }
    },
    watch: {
      '$route.params.id': function() {
        this.load(this.$route.params.id);
      },
      '$store.state.nftContract': function() {
        this.load(this.$route.params.id);
      }
    },
    methods: {
      load: function(id) {
        if(!this.$store.state.nftContract) {
          return;
        }
        this.$store.state.nftContract.tokenURI(id).then(uri => {
          let uriParts = uri.split('/');
          this.nft.equipment = uriParts[uriParts.length - 1];
          fetch(uri).then((response) => {
            return response.json();
          }).then((rat) => {
            this.rat = {...rat, network: null};
            for(var i in rat.attributes) {
              if(typeof rat.attributes[i].power !== 'undefined' && rat.attributes[i].power > 0) {
                this.nft.hasEquipment = true;
              }
            }
            this.metadata = rat;
            this.$store.state.nftContract.nftPower(id).then(power => {
              this.power = power;
            });

            this.$store.state.nftContract.ownerOf(id).then((owner) => {
              this.rat.smart = true;
              this.rat.network = 'SmartBCH'
              this.owner = owner;
            })
          });
        }).catch(() => {
          this.rat.network = 'Unknown';
          this.rat.smart = false;
          this.nft.equipment = id.toString();
          fetch('https://api.cryptor.at/original/nft/' + id).then((response) => {
            return response.json();
          }).then((rat) => {
            this.rat = rat;
            this.metadata = rat;
            this.power = rat['rat-iq'];
            this.owner = null;

            if(typeof this.metadata !== 'undefined' && typeof this.metadata.ct !== 'undefined' && this.metadata.ct) {
              this.rat.network = 'CT';
            } else {
              this.rat.network = 'SLP';
            }
          });
        });
      }
    },
    mounted() {
      this.load(this.$route.params.id);
    }
  }
</script>
