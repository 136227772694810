<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card mb-3">
          <div v-if="!transferred" class="card-body">
            <strong>CashTokens:</strong><br/>
            <div class="input-group mb-3">
              <button class="btn btn-primary" @click="burnNft()">{{transferButtonLabel}}</button>
            </div>
            <p>Warning: Once your $RAT is sent, you cannot modify its traits anymore! Think carefully!</p>
          </div>
          <div v-if="transferred" class="card-body">
            $RAT transferred to <router-link to="/ct">CT bridge</router-link><br/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      transferButtonLabel: 'Send to CT Bridge',
      transferAddress: null,
      transferred: false,
    }
  },
  props: ['id'],
  methods: {
    burnNft: async function() {
      this.transferButtonLabel = 'Sending transaction...';
      let tx = await this.$store.state.nftContract.burn(this.id)
      await tx.wait();
      this.transferred = true;
    },
  }
}
</script>